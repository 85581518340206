// src/App.js
import React from 'react';
import VideoPlayer from './VideoPlayer';
import './App.css';
import './index.css';

function App() {
    return (
        <div className="App">
            <VideoPlayer />
        </div>
    );
}

export default App;